import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

// Utilities
import kebabCase from "lodash/kebabCase"

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  //This is how to add logic into the arrow function
  //We want to reorder the graphql output to sort by tag totalCount
  group.sort(function (a, b) {
    return b.totalCount - a.totalCount;
  });
  return (
<Layout location="Tag location" title={title}>
<Seo title="All Tags" />
  <article>
    <div>
    <StaticImage
        className="floaty-icon"
        layout="fixed"
        placeholder="none"
        formats={["auto", "webp", "avif"]}
        src="../images/tag.png"
        width={50}
        height={50}
        quality={50}
        alt="Profile picture"
      />
      <h1>Tags</h1>
      <ul className="tag-results">
        {group.map(tag => (
          <li key={tag.fieldValue}>
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} <span className="details">({tag.totalCount})</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </article>
</Layout>
)}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`